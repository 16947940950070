import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import BgVideo from '../video/viddget-hero.mp4'
import BankProduct from '../images/viddget_bank.png'
import TelcoProduct from '../images/viddget_support.png';
import CommerceProduct from '../images/viddget_shop.png'
import TravelProduct from '../images/viddget_travel.png'
import FirstBankLogo from '../images/firstbanklogo.png'
import SamsungLogo from '../images/samsung.png'
import VodafoneLogo from '../images/vodafone.png'
import TrivagoLogo from '../images/trivago.png'
import TelecomPdf from '../images/Viddget-Telco.pdf'
import BankPdf from '../images/Viddget-Bank.pdf';
import ECommercePdf from '../images/Viddget-E-commerce.pdf'
import TravelPdf from '../images/Viddget-Travel.pdf';
import TextLoop from "react-text-loop";
import ReactPlayer from 'react-player'
import IntroductionPosterImage from '../images/video-posters/introduction.jpg';
import LowConversationRatePosterImage from '../images/video-posters/low_conversion_rate.jpeg';
import DigitalOnboardingPosterImage from '../images/video-posters/digital_onboarding.jpeg';
import IdentifyYourCustomersOnlinePosterImage from '../images/video-posters/identify_your_customers_online.jpeg';
import SameExperienceOnlinePosterImage from '../images/video-posters/same_experience_online.jpeg';


const IndexPage = () => (
  <Layout>
    <SEO title="Home" keywords={[`video chat`, `chat`, `video`]} />
    <div className="hero">
      <div className="cover"></div>
      <video playsInline autoPlay muted loop >
        <source src={BgVideo} type="video/mp4" />
      </video>
      <div className="container dir-col">

        <h1><TextLoop className="rolling-header" interval={1500} children={[
         "Interactive Video meeting - the markets best video chat",
         "Same experience online as in store",
         "Easy to use & Easy to access - browser based technology",  
         "More satisfied customers and increased sales",
         "Meet your customers online – No travel needed",
         "Compatible with tablets and smartphones",
         "No installation is required from the customer"]} noWrap={false} mask={true} /></h1>

      </div>
    </div>

    <div className="container space-between" id="what-is-viddget">
      <div className="text-wrapper">
        <h2>What is Viddget?</h2>
        <p>Viddget is a unique video chat solution that enables the company representatives to communicative with the customers in a more humanized and personalized manner through an easy-to-use video chat, with no installations needed on the customer side. <br /> <br /> The solution is ideal for banks, insurance and telecommunication organizations, as well as e-commerce companies. In today’s competitive market, service is sales and Viddget adds a more personalized human touch to all digital customer interactions.</p>
      </div>
      <div className="video-wrapper">
        <ReactPlayer url='https://s3.eu-central-1.amazonaws.com/viddget-website-assets/video/viddget-introduction-a3ff1fe64ed51a4bbe2644f8679fee88.mp4' controls light={IntroductionPosterImage} playing className="video-player" width="100%" height="100%" />
        </div>
    </div>
    
    <div id="book-a-demo">
      <div className="request-demo">
            <div className="container text-center">
            <a href="mailto:info@viddget.com?subject=Demo request&body=Hi,%0D%0A I would like to request a demo on Viddget." className="btn primary">Request a demo</a>
            </div>
      </div>
    </div>

    <div className="product-section" id="products">
      <div className="container space-between">
      <h2>Our Products</h2>
      </div>

      <div className="container no-padding-top space-between">
            <div className="text-wrapper">
            <h3>Viddget Telecom</h3>  
            <p>Viddget Telecom enables you to meet your customers online, in new Sales explaining and comparing the customers many options in choosing the right plan and assisting the customer through the entire sales process where Viddget Telecom has basket functionality, online payment, Online signage to mention some. <br />  <br /> Viddget Telecom is also used as a post purchase service to welcome new customers and make sure they get started correctly and become happy customers. For more information please contact us.</p>
                <a href={TelecomPdf} className="btn" download>Download product information</a>  
            </div>
            <img src={TelcoProduct} alt="Telco" /> 
        </div>


        <div className="container space-between">
        <img src={BankProduct} alt="Banking" />
        <div className="text-wrapper">
        <h3>Viddget Bank</h3>        
            <p>Viddget Bank product is easiest explained as a branch office online.
With Viddget Bank you can meet your customers online, guide them through your products and services, identify them through the markets leading KYC technology that Viddget is integrated with and much more. <br /><br /> Viddget Bank makes your digital onboarding easy!
For more information please contact us.</p>
    <a href={BankPdf} className="btn" download>Download product information</a>
        </div>
      </div>


      <div className="container space-between">
      <div className="text-wrapper">
      <h3>Viddget E-commerce</h3>
            <p>Vidget E-commerce is very powerful. Use Viddget E-commerce to help and assist your customers when they are on your site. <br /><br /> With Viddget E-commerce you can text chat with your customers and co-surf with them and guide them to the right site and if needed you can move from text chat to a Video meeting if there is anything you need to show or explain.
For more information please contact us.</p>
    <a href={ECommercePdf} className="btn" download>Download product information</a>
      
      </div>
      <img src={CommerceProduct} alt="E-commerce" />
      </div>


      <div className="container space-between">
      <img src={TravelProduct} alt="Travel" />
      <div className="text-wrapper">
      <h3>Viddget Travel</h3>
            <p>Viddget Travel is a product that brings your travel agency to your customers home.
Help your customers in real time to manage bookings and reservations that require more assistance, group bookings, special needs bookings etc. <br /><br /> With Viddget Travel you have the tools needed to give your customers the best online experience there is.</p>
    <a href={TravelPdf} className="btn" download>Download product information</a>
      </div>
      </div>


     
      </div>
   

    <div className="assistance-section" id="how-to-use">
      <div className="container space-between">
        <h2>How to use Viddget</h2>
      </div>

      <div className="container no-padding-top space-between">
        <div className="text-wrapper">
          <h3>Digital onboadring</h3>
          <p>The challenge with digital onboarding is that it is online without anyone to help.<br />
          With Viddget, you can easily meet your new customers online, no installation or registrations required, you can identify and assist your customer in real time & really make sure that your customers get started the way they should.
          </p>
        </div>
        <div className="video-wrapper">
          <ReactPlayer url='https://s3.eu-central-1.amazonaws.com/viddget-website-assets/video/viddget-digital-onboarding-5805eb68f3ccaac98390d7ebfb68d686.mp4' controls light={DigitalOnboardingPosterImage} playing className="video-player" width="100%" height="100%" />
        </div>
      </div>

      <div className="container space-between">
        <div className="video-wrapper">
          <ReactPlayer url='https://s3.eu-central-1.amazonaws.com/viddget-website-assets/video/viddget-same-experiance-online-1c92a8df2d2ae46fa045d19ee2d37f18.mp4' controls light={SameExperienceOnlinePosterImage} playing className="video-player" width="100%" height="100%" />
        </div>
        <div className="text-wrapper left-padding">
          <h3>Same experience online as in store</h3>
          <p>Get the same assistance online as you expect when entering a store. Compare products, ask questions to a real person and get help finding what you need. 
          </p>
        </div>
      </div>


      <div className="container space-between">
        <div className="text-wrapper">
          <h3>Identify your customer online</h3>
          <p>In Viddget you can do much more than just talk to your customers. We have developed API’s to the markets best KYC platforms, for example the ability to identify a person online using Facebiometrics or BankID.
          </p>
        </div>
        <div className="video-wrapper">
          <ReactPlayer url='https://s3.eu-central-1.amazonaws.com/viddget-website-assets/video/viddget-identify-customers-online-0bf6c001b7c443c9be3a53178758824b.mp4' controls light={IdentifyYourCustomersOnlinePosterImage} playing className="video-player" width="100%" height="100%" />
        </div>
      </div>

      <div className="container space-between">
        <div className="video-wrapper">
          <ReactPlayer url='https://s3.eu-central-1.amazonaws.com/viddget-website-assets/video/viddget-low-conv-high-abandonment-a670b8f9187e65a1eb65be210f96f509.mp4' controls light={LowConversationRatePosterImage} playing className="video-player" width="100%" height="100%" />
        </div>
        <div className="text-wrapper left-padding">
          <h3>Low Conversion Rate</h3>
          <p>We all agree that Internet is here to stay, and we can also agree that more and more of today’s business is dependent on a digital presence. <br/> So why not make it as good as it can be. We have seen higher conversion rate and lowered return rates plus happier customers.
          </p>
        </div>
      </div>

    </div>
  <div className="brand-section">
    <div className="container">
      <h2>Some of our customers</h2>
    </div>
    <div className="container no-padding-top">
      <img src={FirstBankLogo} alt="FirstBank Logo" />    
      <img src={SamsungLogo} alt="Samsung Logo" />
      <img src={VodafoneLogo} alt="T-Mobile Logo" />
      <img src={TrivagoLogo} alt="Trivago Logo" />
    </div>
  </div>

  </Layout>
)

export default IndexPage
